//Variables
$linkColor: #37424A;
$disabledColor: #616B72;

@mixin flexrow-spaceBetween {
  flex-direction: row;
  justify-content: space-between;
}

// block
.result-table-header {
  margin-top: 20px;
  margin-bottom: 20px;

  hr {
    margin: 10px 0 18px;
    border: 0;
    border-top: 2px solid #dcdee0;
  }
}

.warning-container {
  margin-top: 20px;

  lfg-alert-message {
    .alert-message--warning {
      border: 2px solid #ce6b27;
      background: unset;
    }

    .alert-message .fas {
      font-size: 18px;
    }
  }

}

.result-table--title {
  font-weight: 500;
  margin-bottom: 10px;
}

.result-table__export {
  display: flex;
  justify-content: space-between;

  @include mobile() {
    flex-direction: column;
  }

  p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.result-table__export--list {
  display: inline-flex;
  margin-left: 5px;
  margin-right: 0;

  @include mobile() {
    margin-left: 0;
  }

  >li {
    margin-right: 5px;
    vertical-align: top;
    margin-left: 3px;
    color: #666;
    font-size: 14px;
    line-height: 16.8px;

    @include mobile() {
      margin: 0px;
      width: 100%;
      display: flex;
      @include flexrow-spaceBetween();
    }

    form {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include mobile() {
        width: 100%;
        flex-direction: column;
      }

      label {
        margin-right: 10px;

        @include mobile() {
          width: 100%;
          padding-bottom: 10px;
          margin-right: 0px;
        }
      }
    }

    @include mobile() {
      lfg-dropdown {
        width: 100%;
      }
    }
  }
}

.result-table-section,
.result-modalTable-section {
  .account_link {
    font-size: 13px;
    font-weight: bold;
    color: #036d9b;
    cursor: pointer;
  }

  .result-table-desktoplink--section {
    display: flex;
  }

  .result-table-mobilelink--section {
    display: none;

    @include mobile() {
      width: 100%;
      flex-direction: column;
    }
  }

  .modal-popup__focus-trap {
    visibility: hidden;
  }

  @include mobile() {
    .result-table-desktoplink--section {
      display: none;
    }

    .result-table-mobilelink--section {
      display: flex;
    }
  }

  .result-table-desktoplink--section,
  .result-table-mobilelink--section {
    width: 100%;
    @include flexrow-spaceBetween();
    background-color: #F2F4F6;

    ul {
      display: flex;
      flex-direction: row;
      padding: 10px 15px;
      color: #616B72;
      font-size: $font-size-larger;
      overflow: hidden;

      li {
        margin: 0px 15px;
        color: $linkColor;
        font-size: $font-size-default;

        @include mobile() {
          margin: 0px;
        }

        strong {
          color: $linkColor;
        }

        a {
          color: #036d9b;
        }

        img {
          margin-right: 10px;
        }
      }

      li.noRightmargin {
        margin-right: 0px !important;
      }

      li.noOfSelectedItem {
        margin-left: 0px;

        @include mobile() {
          border-right: 1px solid #d4d4d4;
          padding-right: 10px;
          margin-right: 0px;
        }
      }

      @include mobile() {
        li.dbPageTable__mobActions {
          display: flex;
          @include flexrow-spaceBetween();
          align-items: center;
          overflow-x: auto;
          overflow-y: hidden;

          span {
            margin: 0px 10px;
          }
        }
      }
    }

    .result-table-warn {
      margin: auto 10px auto 15px;
    }
  }
}

.result-table-section .lfg-custom-table-cont {
  @include mobile() {
    overflow-x: auto;
  }
}

.result-table-section .lfg-table-primary,
.result-modalTable-section .lfg-table-primary {
  width: 100% !important;
  margin-bottom: 30px;
  font-size: 14px;

  .caseCheckBoxLabel {
    display: inline;

    span {
      position: absolute;
      visibility: hidden;
    }
  }

  .lfg-table-primary__thead {
    tr th span.hiddenTh {
      visibility: hidden;
    }
  }

  @include mobile() {
    margin-bottom: 0;
  }
}

.result-table-section {
  tbody {
    max-height: 250px;
    overflow-y: auto;
  }

  .pagination-container {
    ul {
      li {
        span.btn-divider {
          color: $linkColor;
        }
      }
    }
  }
}

.result-table-section .lfg-table-primary__td,
.result-modalTable-section .lfg-table-primary__td {
  text-align: left;
  vertical-align: middle;
  font-size: 14px;

  .primary-table--viewBtn {
    border: 0px;
    outline: 0px;
    background-color: transparent;
    padding: 0px;
    color: #036d9b;
    cursor: pointer;
    font-size: 13px;
  }
}

.result-table-section .result-table-td_clntName {
  font-size: 14px;
  font-weight: normal;
  color: $linkColor;

  a {
    font-weight: normal;
    font-size: 14px;
  }
}

.lfg-table-primary__th a {
  font-weight: 300 !important;
  font-size: 14px !important;
}

.lfg-table-primary__thead {
  text-transform: none !important;
}

.link-is-disabled a {
  color: #B0B5B8;
  cursor: not-allowed;
  text-decoration: none;
  pointer-events: none;
}

.link-is-disabled button {
  background-color: #d8d8d8;
  cursor: default;
  pointer-events: none;
}

.eticket-dbResult-table__checkbox {
  text-align: center !important;
  width: 40px;
  vertical-align: middle;
}

input[type="checkbox"][disabled]+label::before {
  background: rgba(220, 222, 224, 1);
  border: 2px solid rgba(191, 191, 191, 1);
  height: 15px;
  width: 15px;
  cursor: default;
}

.lfg-table-primary__th .inp-display-text--chkbox {
  display: inline-block;
  margin: 5px auto;
  justify-content: center;
}

.lfg-table-primary__th .inp-text--chkbox {
  display: flex;
  font-size: 11px;
  justify-content: center;
}

.warn-icon {
  color: $color-warning-border;
  margin-right: 10px;
}

.complete-icon {
  color: #00747B;
  margin-right: 10px;
}

.eticket-dbResult-table-th_clntName {
  min-width: 110px !important;
}

.table-th_status_icon {
  font-size: 16px;
  padding-right: 5px;
  top: 1px;
  position: relative;
}

button.lfg-table-primary__th-sort-btn {
  padding-left: 0px;
}

.eticket-dbResult-table-th_policy {
  min-width: 100px;
}

.result-modalTable-section {
  overflow-y: auto;
  height: 300px;
}

.result-modalTable-section thead th {
  position: sticky;
  top: 0;
}

.result-modalTable-section tbody tr td:first-child {
  font-weight: 500;
  min-width: 120px;
}

.cursor-pointer {
  cursor: pointer;
}