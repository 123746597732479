@import "@styles/lfg-styles/core/colors";
@import "@styles/lfg-styles/core/config";
@import "@styles/lfg-styles/mixins/breakpoints";
@import "@styles/lfg-styles/mixins/forms";
@import "@styles/lfg-styles/mixins/fonts";
@import "@styles/lfg-styles/mixins/typography";
@import "@styles/lfg-styles/mixins/scaffolding";

@import 'eticket-dashboard-table.component.scss';

$headingColor: #37424a;
$disabledColor: #616B72;

.content-area {
  @include mobile() {
    padding: 10px;
  }
}

h1 {
  margin: 0 0 25px 0;
}

h2 {
  color: $color-heading-gray !important;
  margin: 0px;
  width: 100%;
  margin-bottom: 25px;
}

.tooltip-container {
  margin-left: 10px;
}

.lfg-checkbox label {
  font-size: 16px;
}

input[type=text][disabled]+.inp-float__placeholder--float {
  background-color: #d8d8d8 !important;
  -webkit-text-fill-color: #000;
  /* Override iOS / Android font color change */
  -webkit-opacity: 1;
  /* Override iOS opacity change affecting text & background color */
}

input[type=text][disabled]+.inp-float__placeholder {
  background-color: #d8d8d8 !important;
  -webkit-opacity: 1;
}

.lfg-customRadio-field {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;

  @include mobile() {
    margin-right: 0px;
  }

  @include tablet() {
    margin-right: 20px;
  }

  .lfg-radio-section {
    display: flex;
    flex-direction: row;

    .custom-radio-button {
      height: 38px;
      display: flex;
      flex-direction: row;

      @include mobile() {
        margin-top: 15px;
      }

      @include tablet() {
        margin-top: 15px;
      }
    }

    .question-radio_text {
      padding-right: 10px;
      font-weight: 500;
      color: #37424A;
      margin-top: auto;
      margin-bottom: auto;
    }

    @include mobile() {
      flex-direction: column;
    }

    @include tablet() {
      flex-direction: column;
    }
  }

  >p {
    margin: 0px 10px 10px 0px;
  }

  >div {
    min-height: 20px;
    min-width: 170px;
  }

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked {
      + {
        label {
          background-color: #0D5DA6;
          box-shadow: none;
          color: $color-heading-white;
        }
      }
    }
  }

  input[type=radio][disabled]:not(.ng-valid)+label {
    border: 1px solid $input-locked-bg-border-color !important;
  }

  input[type=radio][disabled]+label {
    background-color: $input-locked-bg-color !important;
  }

  input:checked[disabled]+label {
    color: #000 !important;
  }

  input[type=radio][disabled]:checked+label {
    color: #FFFFFF !important;
    background: #616B72 !important;
    border: 1px solid #616B72 !important;
  }

  input[type=radio]:not(.ng-valid):checked+label {
    color: #FFFFFF !important;
  }

  label {
    background-color: $color-heading-white;
    color: rgba(13, 93, 166, 1);
    font-size: 16px;
    line-height: 1;
    text-align: center;
    padding: 10px 16px;
    margin-right: -2px;
    border: 1px solid rgba(13, 93, 166, 1);
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
}

.progressBar-tabs__container {
  .lfg-overlay-wrapper {
    background: rgba(0, 0, 0, 0.5) !important;

    .lfg-overlay {
      height: 100%;
      padding-top: 0px;
    }

    .lfg-overlay__close {
      display: none !important;
    }
  }
}

.lfg-table-primary__td .inp-lbl {
  padding-left: 20px !important;
}

input.form-field-locked[type=text][disabled]+.inp-float__placeholder {
  background: $input-locked-bg-color;
  color: $color-text-content;
  -webkit-text-fill-color: #000;
  /* Override iOS / Android font color change */
  -webkit-opacity: 1;
  /* Override iOS opacity change affecting text & background color */
}

input[type=text][disabled],
input[type=search][disabled],
input[type=number][disabled],
input[type=email][disabled],
input[type=password][disabled] {
  background: $input-locked-bg-color;
  border-color: $input-locked-bg-border-color;
  color: $input-locked-bg-color;
  -webkit-text-fill-color: #000;
  /* Override iOS / Android font color change */
  -webkit-opacity: 1;

  /* Override iOS opacity change affecting text & background color */
  &.inp-float__pwd {
    pointer-events: none;

    .inp-float__pwd-icon {
      color: $input-locked-bg-border-color;
    }
  }
}

.btn--link-disabled {
  color: $input-locked-bg-border-color !important;

  i {
    color: $input-locked-bg-border-color !important;
  }
}

a.disableLink {
  color: $disabledColor !important;
  text-decoration: none;
  pointer-events: none;

  i {
    color: $disabledColor !important;
    pointer-events: none;
  }
}

input[type=checkbox][disabled] {
  pointer-events: none;
}

input[type=text][disabled]+.inp-float__placeholder,
input[type=search][disabled]+.inp-float__placeholder,
input[type=number][disabled]+.inp-float__placeholder,
input[type=email][disabled]+.inp-float__placeholder,
input[type=password][disabled]+.inp-float__placeholder {
  background: $input-locked-bg-color;
  color: $color-text-content;
  -webkit-text-fill-color: #000;
  /* Override iOS / Android font color change */
  -webkit-opacity: 1;
  /* Override iOS opacity change affecting text & background color */
}

input[type=radio][disabled]+label {
  background-color: unset !important;
  color: #37424A;
}

.lfg-radio input[type=radio][disabled]:checked+label::after {
  background: #AAAAAA;
}

.lfg-cal input.form-field-locked[type=text]:disabled {
  border: 2px solid $input-locked-bg-border-color;
}

.ng-dirty+.lfg-dropdown .lfg-dropdown__display--error {
  background: url(/assets/images/ex.png) calc(100% - 25px)/25px auto no-repeat #fff2f4 !important;
}

.no-margin-top {
  margin-top: 0px;
}

.page-wrapper .form--control {
  width: 30%;
  max-width: 260px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;

  @include tablet() {
    width: 30%;
  }

  @include mobile() {
    width: 100%;
    max-width: 100%;
  }

  .text-area-count {
    padding-top: 5px;
    font-size: 14px;
    color: #37424a;
    position: relative;
    float: right;
  }
}

.form--length-btn {

  .btn {
    padding: 12px 32px;
    width: max-content;
  }

  @include mobile() {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.page-wrapper .form-dropDown-multi-checkbox {
  width: 60%;
  max-width: 100%;

  @include tablet() {
    width: 100%;
    max-width: 100%;
  }

  @include mobile() {
    width: 100%;
    max-width: 100%;
  }
}

.page-wrapper .form--control .inp-float {
  margin-bottom: 0px !important;
}

.page-wrapper .questionText,
.page-wrapper .questions-header {
  font-family: "Roboto Regular", "Roboto", sans-serif;
  font-size: $font-size-default;
  color: $color-heading-gray;
}

.page-wrapper .questionText {
  margin-bottom: 10px;
}

.page-wrapper .questions-header {
  margin-bottom: 20px;
}

.page-wrapper .question-radio,
.page-wrapper .question-checkbox {
  padding-bottom: 20px;
  padding-right: 20px;
  display: inline-flex;

  @include tablet() {
    padding-right: 0px;
  }

  @include mobile() {
    padding-right: 0px;
  }
}

lfg-accordion::ng-deep {
  .accordion-wrapper .accordion {
    .accordion__header {

      a,
      a:active,
      a:focus,
      a:hover {
        outline: none;
        text-decoration: none;
      }
    }

    .accordion__content {
      outline: none;
    }
  }
}

@media screen and (min-width: 989px) and (max-width: 1030px) {

  .page-wrapper .question-radio,
  .page-wrapper .question-checkbox {
    padding-right: 0px !important;
  }
}

.textarea-parent {
  width: 70% !important;
  max-width: none !important;

  @include tablet() {
    width: 100% !important;
    max-width: none !important;
  }

  @include mobile() {
    width: 100% !important;
    max-width: none !important;
  }
}

.textarea-parent textarea {
  width: 100% !important;
  resize: none;

  @include mobile() {
    width: 100%;
    height: 100px !important;
  }

  &.inp-txt--err {
    border-color: #a00;
    background: url("../../../assets/images/input-error.png") calc(100% - 8px) / 25px auto no-repeat #fff2f4;
    padding-right: 25px;
    margin-bottom: -5px;
  }
}

.page-wrapper .question-radio .question-radio_text,
.page-wrapper .question-checkbox .question-checkbox_text {
  margin-bottom: 0px;
  line-height: 18px;
}

.page-wrapper .question-radio .lfg-radio--single,
.page-wrapper .question-checkbox .lfg-checkbox--single {
  font-family: "Roboto Regular", "Roboto", sans-serif;
  font-size: $font-size-default;
  color: $color-heading-gray;
  padding-right: 25px;
  display: inline;
}

.page-wrapper .form--control__full-width,
.page-wrapper {
  width: 100%;
  max-width: none;
}

.page-wrapper .nested-questions {
  padding: 20px 20px 0px;
  margin-bottom: 25px;
  background: $color-info-bg;
  border-top: 1px solid $color-uibutton-disabled;
  border-bottom: 1px solid $color-uibutton-disabled;
  position: relative;
}

.inp-float__placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  max-width: calc(100% - #{20px});
}

.lfg-cal .lfg-cal__date-input {
  background: url(../../../assets/images/calender.svg) right 10px center/20px 20px no-repeat transparent;
}

// tslint:disable-next-line: selector-type-no-unknown
lfg-calendar .inp-float__placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  max-width: calc(100% - #{40px});
}

input[type="password"].inp-float__placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  max-width: calc(100% - #{40px});
}

.page-wrapper .grid-subHeading-parent {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-wrapper .remove-grid-container {
  display: flex;
  justify-content: flex-end;
}

.page-wrapper .remove-grid-container .far.fa-trash-alt {
  margin-right: 5px;
}

.page-wrapper .nested-questions .add-grid {
  margin-top: 10px;
  margin-bottom: 10px;
}

.page-wrapper .far.fa-trash-alt {
  cursor: pointer;
  color: #036d9b;
}

.page-wrapper .lfg-cal:not(.calendar--err) input {
  background-color: #fff;
}

.page-wrapper .far.fa-trash-alt.disable-delete {
  pointer-events: none;
  color: $input-locked-bg-border-color;
}

.questions--container {
  margin-top: 15px;

  .lfg-color {
    border-bottom: 1px solid $input-locked-bg-color;
    padding-bottom: 10px;
  }

  @include mobile() {
    margin-top: 5px;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.element-for-screen-reader {
  opacity: 0;
  font-size: 18px;
  line-height: 0;
  position: absolute;
  margin: 0px;
  display: none;
  background-color: #fff;
}

.section-divider {
  border-top: 1px solid #d7d7d7;
  margin-bottom: 30px;
}

.page-content-container {
  h1 {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 30px;
    line-height: 32px;
    color: $headingColor;
  }

  .questions-header h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #37424a;
    margin: 25px 0 10px 0;
  }

  input[type=text][disabled] {
    color: black !important;
    -webkit-text-fill-color: #000;
    /* Override iOS / Android font color change */
    -webkit-opacity: 1;
    /* Override iOS opacity change affecting text & background color */
  }

  .nested-questions .questionText {
    margin-top: 0px !important;
    padding-top: 0px !important;
    border-top: 0px !important;
  }

  .form-dropDown {
    max-width: 300px;
    width: 31%;

    @include mobile() {
      width: 100%;
      max-width: 100%;
    }

    @include tablet() {
      width: 45% !important;
    }

    .screen-reader-only {
      margin-bottom: 10px !important;
      display: block;
    }
  }

  .form-control-medium {
    width: 100% !important;
    max-width: 45% !important;

    @include tablet() {
      width: 100% !important;
      max-width: 100% !important;
    }

    @include mobile() {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .form--control {
    width: 30%;
    max-width: 255px;

    @include mobile() {
      width: 100%;
      max-width: 100%;
    }

    @include tablet() {
      width: 45%;
      max-width: 100%;
    }

    &__full-width {
      width: 100%;
      max-width: 100%;

      @include mobile() {
        width: 100%;
      }

      @include tablet() {
        width: 100%;
      }
    }

    &__medium-width {
      width: 62%;
      max-width: 62%;
      display: block;

      @include mobile() {
        width: 100%;
        max-width: 100%;
      }

      @include tablet() {
        width: 100%;
        max-width: 100%;
      }
    }



    .btn:hover {
      @include mobile() {
        background-color: $color-uibutton-normal;
      }

      @include tablet() {
        background-color: $color-uibutton-normal;
      }
    }
  }

  .question-dropdown--medium {
    width: 60%;
    max-width: 400px;

    @include mobile() {
      width: 100%;
      max-width: 100%;
    }

    @include tablet() {
      width: 100% !important;
      max-width: 100%;
    }
  }

  .question-radio--small {
    max-width: 274px;
    width: 32%;
    display: inline-flex;
    padding-top: 6px !important;

    @include mobile() {
      width: 100%;
      max-width: 100%;
      padding-top: 10px !important;
    }

    @include tablet() {
      width: 49%;
      max-width: 250px !important;
      min-width: 242px;
    }
  }

  .question-radio--medium {
    width: 60%;
    display: inline-flex;
    padding-top: 0px !important;

    @include mobile() {
      width: 100%;
      max-width: 100%;
      flex-direction: column;
      margin-bottom: 20px;
    }

    @include tablet() {
      width: 100%;
      max-width: 100%;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  .lfg-customRadio-field-long {

    .question-radio_text {
      font-weight: 500;
    }

    .radio-button-container {
      display: inline-block;
    }

    .radio-input-label.radio-input-label-child:not(.radio-button-checked) {
      background: #fff;
    }

    .radio-input-label {
      border: 1px solid #036D9B;
      border-radius: 5px;
      min-height: 40px;
      margin: 20px 0px;
      padding: 10px 10px;
      flex-direction: row;
      display: flex;
      cursor: pointer;

      label {
        margin-left: 10px;
        font-size: 16px;
        color: #036D9B;
      }

      input {
        display: inline-block;
        z-index: -1;
        position: absolute;

        &:checked {
          + {
            label {
              background-color: #036D9B;
              box-shadow: none;
              color: #fff;
            }
          }
        }
      }

      .radio-icon {
        font-size: 18px;
        color: #036D9B;
        align-items: center;
        display: flex;
      }

      .radio-icon-checked {
        color: #fff;
      }
    }

    .radio-button-checked {
      background-color: #036D9B;
      color: #fff;
    }
  }

  // style for disable radio button long
  .lfg-customRadio-field-long:has(input[type=radio][disabled]) {
    .radio-input-label {
      background-color: $input-locked-bg-color;
      border: 1px solid $input-locked-bg-border-color;

      label {
        color: #37424A;
      }
    }

    .radio-icon {
      color: #37424A;
    }
  }
}

.lfg-customRadio-field-long::ng-deep {
  .form-field-err {
    margin-bottom: 20px;
  }
}


.agentInfoModal {
  .lfg-dropdown__options {
    max-height: 125px !important;
  }

}

// for disabling the dropdown

.lfg-dropdown--disabled {
  .lfg-dropdown-expand-button {
    background: $input-locked-bg-color;
    border: 2px solid $input-locked-bg-border-color;
    pointer-events: none;
  }

  .lfg-dropdown-expand-button::after {
    border-top-color: $input-locked-bg-border-color !important;
  }
}

.lfg-dropdown:has(.lfg-dropdown-expand-button[disabled]) {
  .inp-float__placeholder {
    background: $input-locked-bg-color;
    color: #37424A;
  }

  .lfg-dropdown-expand-button[disabled] {
    background: $input-locked-bg-color;
    border: 2px solid $input-locked-bg-border-color;
    color: black !important;
    pointer-events: none;
  }
}

.lfg-dropdown .lfg-dropdown-expand-button[disabled]::after {
  border-top-color: $input-locked-bg-border-color !important;
}

// disabling drop down end here

.lfg-dropdown .lfg-dropdown-expand-button.list-open {
  background-color: white;
}

.form-dropDown {
  .dropdown--err {
    .inp-float.lfg-dropdown:not(.list-open) {
      button[type=button]:not(.list-open) {
        border-color: #a00;
        padding-right: 40px;
        background: url(/assets/images/ex.png) calc(100% - 8px)/25px auto no-repeat #fff2f4;
      }
    }
  }
}

.dropdown--err {
  .lfg-dropdown .lfg-dropdown-expand-button::after {
    z-index: -1;
  }
}

.alert-message--error {
  border: 2px solid #a00;
  background: white;

  .alert-message__content {
    color: black;
  }
}

.alert-message--success .fas {
  color: #84ad00;
}

.alert-message--success {
  border-left: 3px solid #84ad00;
  background: #e8f7e4;
}

.help-icon {
  color: white;
  right: 15px;
  top: 15px;
  position: absolute;
  align-items: center;
  cursor: pointer;

  .help-icon-image {
    padding-right: 10px;
  }
}

.helpData-section {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 450px;
  height: auto;
  background-color: #edf6f8;
  padding: 20px;
}

.help-slide-left {
  position: absolute;
  right: -300px;
  -webkit-animation: slide-out 0.5s forwards;
  animation: slide-out 0.5s forwards;
  height: 100vh;
  overflow-y: auto;
}

@-webkit-keyframes slide-out {
  100% {
    right: 0;
  }
}

@keyframes slide-out {
  100% {
    right: 0;
  }
}

.help-slide-right {
  position: absolute;
  right: 0;
  -webkit-animation: slide-in 0.5s forwards;
  animation: slide-in 0.5s forwards;
}

@-webkit-keyframes slide-in {
  100% {
    right: -300px;
  }
}

@keyframes slide-in {
  100% {
    right: -300px;
  }
}

.help-wrapper {
  z-index: 999;
  width: 320px;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100vh;
  overflow: hidden;
}

.font-bold {
  font-weight: 500;
}

.nested-questions {
  .alert-message--info {
    border: 2px solid #26508B;
    padding: 7px;

    .fa-info-circle.fas {
      color: #26508B;
    }
  }
}

.header-container {
  margin-top: 10px;
}

.form-dropDown::ng-deep {
  lfg-dropdown {
    .lfg-dropdown {
      ul li {
        word-break: break-all;
      }
    }
  }
}

.term-of-use-page, .consent-page {
  .information-content {
    .term-heading {
      border-bottom: 1px solid #37424A;
    }
  }
}
